import axios from "axios";
import cookieCutter from 'cookie-cutter'
var randomstring = require("randomstring");
import Cookies from 'js-cookie';

//register api that use when user register path where it used /page/[page]/signup.js
async function signUp(PostData = {}) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  };
  try {
    const response = await axios.post(process.env.sinUpApi, PostData, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}
//api for login user.  path where it used /page/[page]/login.js
async function loginUser(PostData = {}) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  };
  try {
    const response = await axios.post(process.env.loginApi, PostData, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}
//api for logout user  path where it used /page/_app.js
async function logoutApi(PostData) {
  const headers = {
    'Authorization': PostData,

  };
  const data = '';
  try {
    const response = await axios.post(process.env.logoutApi, data, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}
//api for invite link--------

async function invitationLink(action = "get", PostData = {},) {
  const headers = { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('Token') };
  try {
    let response = "";
    if (action === "post") {
      response = await axios.post(process.env.invitationLink, PostData, { headers });
    } else {
      response = await axios.get(process.env.invitationLink, { headers });
    }
    return response;
  } catch (error) {
    return error.response;
  }
}
//lickclicked api 

async function clickedapi(PostData = {}) {
  const headers = { 'Content-Type': 'application/json' };
  try {
    const response = await axios.post(process.env.clickedapi, PostData, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

async function CheckUserEmail(PostData) {
  const headers = { 'Content-Type': 'application/json' };
  try {
    const response = await axios.post(process.env.Check_email_newuser, PostData, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}


// api for add product to cart  path where it used /page/product/[name]/[id].js
async function addToCart(PostData = {}) {
  const headers = {
    'Authorization': localStorage.getItem('Token')
  };
  let cookie = "";
  if (localStorage.getItem('Token') === "" || localStorage.getItem('Token') === undefined || localStorage.getItem('Token') === null) {
    if (Cookies.get('sessionkey')?.length !== 16) {
      cookie = randomstring.generate(16), { domain: `.${location.hostname}.com` };
      Cookies.set('sessionkey', cookie)
    }
  }
  const PostDataa = { "cookie_id": cookie || Cookies.get('sessionkey'), "product_id": +(PostData.product_id), "variant_id": PostData?.variant_id ? +(PostData.variant_id) : null, "quantity": +(PostData.quantity), "is_autoship": PostData?.is_autoship }
  try {
    const response = await axios.post(process.env.addToCart, PostData?.datas ? PostData.datas : PostDataa, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// ipayout reister api
async function payoutRegister(postData, action = "get") {
  const headers = {
    Authorization: localStorage.getItem("Token"),
    "Content-Type": "application/json",
  };
  try {
    let response = "";
    if (action === "get") {
      response = await axios.get(process.env.payoutRegister, { headers });
    } else {
      response = await axios.post(process.env.payoutRegister, postData, {
        headers,
      });
    }
    return response;
  } catch (error) {
    return error.response;
  }
}

//api for get banner details and categoried listing according to store , path where it used /page/index.js
async function getBanners(PostData) {
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };

  try {
    const response = await axios.get(`${process.env.getBanners}?slug=${PostData}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

//api for promoter pack products
async function promoterPackApi(payload) {
  const headers = {
    // 'Authorization': `${payload.token}`,
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };

  try {
    const response = await axios.get(`${process.env.promoterPackApi}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

//apui for getdashboardpopup//
async function getdashboardpopup() {
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };

  try {
    const response = await axios.get(`${process.env.getdashboardpopup}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}
// homepage content api
async function getHomepageContent(PostData) {
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };

  try {
    const response = await axios.get(`${process.env.getHomepageContent}?slug=${PostData}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

//saveCustomerReview api
async function saveCustomerReview(PostData) {
  const headers = {
    'Authorization': localStorage.getItem('Token'),
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };

  return axios.post(`${process.env.saveCustomerReview}`, PostData, { headers }).then(function (response) {
    return response;
  }).catch((error) => {
    return error.response;
  });
}

//api for display cart data  path where it used /page/cart/viewcart.js
async function getAllCartProduct(PostData) {
  const timestamp = Date.now();
  const headers = {
    'Authorization': localStorage.getItem('Token'),
  };

  try {
    const response = await axios.get(`${process.env.getAllCartProduct}/?cookie_id=${cookieCutter.get('sessionkey')}&abc=${timestamp}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}


// api for update qty in cart  path where it used /page/cart/viewcart.js
async function updateProductQty(PostData = {}) {
  const timestamp = Date.now();
  const headers = {
    'Authorization': PostData
  };

  try {
    const response = await axios.get(`${process.env.getAllCartProduct}&abc=${timestamp}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}


//api for delete product from cart, path where it used /page/cart/viewcart.js
async function deleteProductFromAddToCart(PostData = {}) {
  const headers = {
    'Authorization': PostData.token,
  };
  const PostDataa = {
    "cookie_id": cookieCutter.get('sessionkey'),
    "product_id": PostData.product_id,
    "id": PostData?.id,
    "is_autoship": PostData?.is_autoship,

    variant_id: PostData?.variant_id ? PostData.variant_id : null
  }

  try {
    const response = await axios.post(process.env.deleteProductFromAddToCart, PostDataa, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// api for get list of address of login user , path where it used /page/checkout/addressList.js
async function manageAddress(PostData) {
  const timestamp = Date.now();
  const headers = {
    'Authorization': PostData
  };

  try {
    const response = await axios.get(`${process.env.manageAddress}?address=${timestamp}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// api for save user address path where it used /page/checkout/address.js
async function saveAddress(PostData = {}) {
  const headers = {
    'Authorization': PostData.token
  };
  const PostDataa = { "data": PostData.data, "operation": PostData.address_type }

  try {
    const response = await axios.post(process.env.manageAddress, PostDataa, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

//api for menu option on header
async function MenuSectionOption() {
  try {
    const response = await axios.get(process.env.menuSection);
    return response;
  } catch (err) {
    return err.response;
  }
}

// api for delete address ,path where it used /page/checkout/addressList.js



// api for get profile related data like persional,address list,order list, path where it used /page/user/profile.js
async function getProfilePageData(PostData) {
  const headers = { 'Authorization': PostData };
  const timestamp = Date.now();

  try {
    const response = await axios.get(`${process.env.getProfilePageData}?xyz=${timestamp}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}
// api for change user login password path where it used /page/user/profile.js
async function changeUserPassword(PostData = {}) {
  const headers = {
    'Authorization': PostData.token
  };
  const PostDataa = { "old_password": PostData.old_password, "new_password": PostData.new_password }

  try {
    const response = await axios.post(process.env.changeUserPassword, PostDataa, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

//api for calculate tax shipping
async function calculateTaxAmount(postData) {
  const headers = { 'Content-Type': 'application/json' };
  try {
    const response = await axios.post(process.env.calculateTaxAmount, postData, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}
// api for add product to wishlist path where it used /page/product/[name]/[id].js

async function addToWishlist(PostData = {}) {
  const headers = {
    'Authorization': `${PostData.token}`
  };
  const PostDataa = { "product_id": +(PostData.product_id), "variant_id": PostData?.variant_id ? +(PostData.variant_id) : null, "quantity": +(PostData.quantity) }

  try {
    const response = await axios.post(process.env.addToWishlist, PostDataa, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// api for show product listing in wishlist path where it used /page/wishlist/wishlist.js
async function getAllWishListProduct(PostData) {
  const headers = {

    'Authorization': PostData
  };

  try {
    const response = await axios.get(process.env.getAllWishListProduct, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// api for delete product from wish list path where it used /page/wishlist/wishlist.js
async function deleteProductFromwishlist(PostData = {}) {
  const headers = {

    'Authorization': PostData.token
  };
  const PostDataa = { "product_id": +(PostData.product_id), "variant_id": +(PostData?.variant_id) ? +(PostData?.variant_id) : null }

  try {
    const response = await axios.post(process.env.deleteProductFromwishlist, PostDataa, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}


//api for get banner details and categoried listing according to store , path where it used /page/index.js
async function getAllProduct(PostData) {
  const headers = {
    'Accept': 'application/json',
    'Authorization': localStorage.getItem('Token')

  };

  try {
    const response = await axios.get(`${process.env.getAllProduct}?slug=${PostData}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}
// filter product
async function filterProduct(PostData, serchdata) {
  const headers = {
    'Accept': 'application/json',
    'Authorization': localStorage.getItem('Token')

  };

  try {
    const response = await axios.get(`${process.env.getAllProduct}?slug=${PostData}&search=${serchdata}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}
//api for get banner details and categoried listing according to store , path where it used /page/index.js
async function getProductByCategories(PostData) {
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };

  try {
    const response = await axios.get(`${process.env.getProductByCategories}?category_id=${PostData}&search=`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

//api for get banner details and categoried listing according to store , path where it used /page/index.js
async function getProductByProductid(PostData) {
  const headers = {
    'Accept': 'application/json',
    'Authorization': localStorage?.getItem('Token') ? localStorage?.getItem('Token') : ''

  };

  try {
    const response = await axios.get(`${process.env.getProductByProductid}?product_id=${PostData}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

async function getProductByVariantid(PostData) {
  const headers = {
    'Accept': 'application/json',
    'Authorization': localStorage.getItem('Token')

  };

  try {
    const response = await axios.get(`${process.env.getProductByVariantid}?variant_id=${PostData}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// Create order
async function createOrder(PostData = {}, token) {
  const headers = {

    'Authorization': token
  };

  try {
    const response = await axios.post(`${process.env.createOrder}`, PostData, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// varify copan
async function verifyCopan(PostData = {}, token) {
  const headers = {
    'Authorization': token
  };

  try {
    const response = await axios.post(process.env.verifyCopan, PostData, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}
// get all category
async function getAllCategory(PostData) {
  const headers = {
    'Accept': 'application/json',
    // 'Authorization': localStorage?.getItem('Token') ? localStorage.getItem('Token') : ''
  };

  try {
    const response = await axios.get(`${process.env.getAllCategory}?slug=${PostData}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

//proven Banifit content api's
async function getProvenBanifitContent(PostData) {
  const headers = {
    'Accept': 'application/json',
  };
  try {
    const response = await axios.get(`${process.env.getProvenbenefitsContent}?slug=${PostData}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// update cart quantity

async function updateCart(PostData = {}) {
  const headers = {
    'Authorization': `${PostData.token}`
  };

  const PostDataa = {
    "product_id": +(PostData.product_id),
    "variant_id": PostData?.variant_id ? +(PostData.variant_id) : null,
    "quantity": +(PostData.quantity),
  }
  try {
    const response = await axios.post(process.env.updateCart, PostData?.datas ? PostData.datas : PostDataa, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}


// api for get user getUserOrder list

async function getUserOrder(PostData, pagenumber) {
  const headers = {
    'Authorization': PostData
  };

  try {
    const response = await axios.get(`${process.env.getUserOrder}?page=${pagenumber}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

//api for get PQV and GV of customer

async function getOrderPQVandGV(PostData) {
  const headers = {
    'Authorization': PostData
  };
  try {
    const response = await axios.get(process.env.getOrderPQVandGV, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}



// api for get user address details

async function getAddressDetails(PostData = {}) {
  const timestamp = Date.now();

  const headers = {

    'Authorization': `${PostData.token}`
  };

  try {
    const response = await axios.get(`${process.env.getAddressDetails}?address_id=${PostData.address_id}&timestamp=${timestamp}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// get specific oder details
async function GetOrderDetail(PostData = {}) {
  const headers = {

    'Authorization': `${PostData.token}`
  };

  try {
    const response = await axios.get(`${process.env.GetOrderDetail}?order_id=${PostData.order_id}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// delete address

async function deleteAddress(PostData, token) {
  const headers = {
    'Authorization': token
  };

  try {
    const response = await axios.post(process.env.deleteAddress, PostData, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// re-pace same order that show in orderlist 

async function reorderProducts(PostData = {}) {
  const headers = {
    'Authorization': `${PostData.token}`
  };

  const PostDataa = { "order_id": +(PostData?.order_id), "cookie_id": cookieCutter.get('sessionkey') }
  try {
    const response = await axios.post(process.env.reorderProducts, PostDataa, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

//get addresslist

async function getAddressList(token) {
  const headers = {

    'Authorization': `${token}`
  };

  try {
    const response = await axios.get(`${process.env.getAddresslist}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

//upcoming orders API

async function upComingOrders(PostData) {
  const headers = {

    'Authorization': PostData
  };

  try {
    const response = await axios.get(process.env.upComingOrders, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// api for get user getUserAutoShipOrder list

async function autoshipOrderHistory(PostData, pagenumber) {
  const timestamp = Date.now();
  const headers = {
    'Authorization': PostData
  };

  try {
    const response = await axios.get(`${process.env.autoshipOrderHistory}?page=${pagenumber}&xyz=${timestamp}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}



// get specific autoshipoder details
async function autoshipOrderById(PostData = {}) {
  const headers = {

    'Authorization': `${PostData.token}`
  };

  try {
    const response = await axios.get(`${process.env.autoshipOrderById}?order_id=${PostData.order_id}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}


// update Autoship

async function autoshipUpdate(PostData = {}, token) {
  const headers = {
    'Authorization': `${token}`
  };

  // const PostDataa = { "product_id": +(PostData.product_id),"variant_id": PostData?.variant_id ? +(PostData.variant_id):null, "quantity": +(PostData.quantity) }
  try {
    const response = await axios.post(process.env.autoshipUpdate, PostData, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}


// delete Autoship
async function autoshipDelete(PostData, token) {
  const headers = {
    'Authorization': token
  };


  try {
    const response = await axios.post(process.env.autoshipDelete, PostData, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// delete autoshipSkip
async function autoshipSkip(PostData, token) {
  const headers = {
    'Authorization': token
  };


  try {
    const response = await axios.post(process.env.autoshipSkip, PostData, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}


// api for Update user address path where it used /page/checkout/address.js
async function updateAddress(PostData = {}) {
  const headers = {
    'Authorization': PostData.token
  };
  const PostDataa = { "data": PostData.data }

  try {
    const response = await axios.post(process.env.updateAddress, PostDataa, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}




// get notification details

async function getUserNotifications(PostData) {
  const headers = {

    'Authorization': `${PostData}`
  };

  try {
    const response = await axios.get(`${process.env.getUserNotifications}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// delete autoship order product

async function autoshipProductDelete(PostData = {}, token) {
  const headers = {

    'Authorization': `${token}`
  };

  try {
    const response = await axios.post(`${process.env.autoshipProductDelete}`, PostData, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}




// update Autoship

async function autoshipProductUpateproduct(PostData = {}, token) {
  const headers = {
    'Authorization': `${token}`
  };

  // const PostDataa = { "product_id": +(PostData.product_id),"variant_id": PostData?.variant_id ? +(PostData.variant_id):null, "quantity": +(PostData.quantity) }
  try {
    const response = await axios.post(process.env.autoshipProductUpateproduct, PostData, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// update Autoship address

async function addressUpdate(PostData = {}, token) {
  const headers = {
    'Authorization': `${token}`
  };

  try {
    const response = await axios.post(process.env.addressUpdate, PostData, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}
// edit smartship data
async function EditSmartshipData(PostData = {}) {
  const headers = { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('Token') };
  try {
    const response = await axios.post(process.env.EditSmartshipData, PostData, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}


// reset password
async function resetPassword(PostData = {}, token) {
  const headers = {
    'Authorization': `${token}`
  };

  try {
    const response = await axios.post(process.env.resetPassword, PostData, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}


async function verifyOtp(PostData = {}, token) {
  const headers = {
    'Authorization': `${token}`
  };

  try {
    const response = await axios.post(process.env.verifyOtp, PostData, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}





//reset confirm password
async function passwordResetConfirm(PostData = {}) {
  try {
    const response = await axios.post(process.env.passwordResetConfirm, PostData);
    return response;
  } catch (error) {
    return error.response;
  }
}


// Cancel Order
async function cancelOrder(PostData = {}, token) {
  const headers = {
    'Authorization': `${token}`
  };

  try {
    const response = await axios.post(process.env.cancelOrder, PostData, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// Refund Order
async function refundOrder(PostData = {}, token) {
  const headers = {
    'Authorization': `${token}`
  };

  try {
    const response = await axios.post(process.env.refundOrder, PostData, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}


// Api for get Bundle product and use in order refund section
async function getBundleProduct(PostData, token) {
  const headers = {
    'Authorization': `${token}`
  };

  try {
    const response = await axios.get(`${process.env.getBundleProduct}/?product_id=${PostData}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}
// Api for get downline user an d used in Dashboard

async function getDownlineUsers(token) {
  const headers = {

    'Authorization': `${token}`
  };

  try {
    const response = await axios.get(`${process.env.getDownlineUsers}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}


// redeemKaireCash Order
async function redeemKaireCash(PostData, token) {
  const headers = {
    'Authorization': `${token}`
  };

  try {
    const response = await axios.get(`${process.env.redeemKaireCash}/?order_total=${PostData}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}


// getRefundHistory Order
async function getRefundHistory(PostData, token) {
  const headers = {
    'Authorization': `${token}`
  };

  try {
    const response = await axios.get(`${process.env.getRefundHistory}/?order_id=${PostData}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// getCommissionReport 
async function getCommissionReport(token, range) {
  const headers = {
    'Authorization': `${token}`
  };

  try {
    const response = await axios.get(`${process.env.getCommissionReport}?value=${range}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// getRefundHistory Order
async function getCommissionsFilter(PostData = {}, token) {
  const headers = {
    'Authorization': `${token}`
  };

  try {
    const response = await axios.get(`${process.env.getCommissionsFilter}?value=${PostData?.filter_by_date}&from_date=${PostData?.from_date ? PostData.from_date : ''}&till_date=${PostData?.til_date ? PostData.til_date : ''}&search_on=order_id&search_value=${PostData?.order_id}&status_filter=${PostData?.status ? PostData?.status : ''}`, { headers });

    return response;
  } catch (error) {
    return error.response;
  }
}
//permalink api
async function permalinkAPI(unique_id) {
  const headers = {
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.get(
      `${process.env.permalinkAPI}?unique_id=${unique_id}`,
      { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

// getRefundHistory Order
async function getRefundReport(token) {
  const headers = {
    'Authorization': `${token}`
  };

  try {
    const response = await axios.get(`${process.env.getRefundReport}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// getRefundReportFilter Order
async function getRefundReportFilter(PostData = {}, token) {
  const headers = {
    'Authorization': `${token}`
  };

  try {
    const response = await axios.get(`${process.env.getRefundReportFilter}?date_filter=${PostData?.filter_by_date}&from_date=${PostData?.from_date ? PostData.from_date : ''}&till_date=${PostData?.til_date ? PostData.til_date : ''}&search_on=order_id&order_id=${PostData?.order_id}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// getRefundHistory Order

async function getMyProfileDetails(token) {
  const timestamp = Date.now();
  const headers = {
    'Authorization': `${token}`
  };
  try {
    const response = await axios.get(`${process.env.getMyProfileDetails}?profile=${timestamp}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// getDashboardCommissions Order
async function getDashboardCommissions(range, token, PostData = {}) {
  const headers = {
    'Authorization': `${token}`
  };

  try {
    const response = await axios.get(`${process.env.getDashboardCommissions}?value=${range}&from_date=${PostData?.from_date ? PostData.from_date : ''}&till_date=${PostData?.til_date ? PostData.til_date : ''}&search_on=order_id&order_id=${PostData?.order_id}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// GetCommissionsApproved Order

async function GetCommissionsApproved(token, range, PostData = {}) {
  const headers = {
    'Authorization': `${token}`
  };

  try {
    const response = await axios.get(`${process.env.GetCommissionsApproved}?value=${range}&from_date=${PostData?.from_date ? PostData.from_date : ''}&till_date=${PostData?.til_date ? PostData.til_date : ''} & search_value=${PostData?.order_id ? PostData?.order_id : ''}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}



// LoginCheck Order
async function LoginCheck(token) {
  const headers = {
    'Authorization': localStorage.getItem('Token') //`${ token }`
  };

  try {
    const response = await axios.get(`${process.env.LoginCheck}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

//support desk apis
async function supportDeskPost(PostData = {}, method, section) {
  const headers = {
    'Accept': "application/json",
    'Authorization': localStorage.getItem('Token') //`${ token }`
  };
  const timestamp = Date.now()
  const formUrl = process.env.supportDesk;
  const formGetUrl = `${process.env.supportDesk}?timestamp=${timestamp}`;

  const chatUrl = process.env.supportDeskReply + "/" + PostData?.id;
  let postDataValue = {};
  let url = "";
  if (section === "save") {
    postDataValue = { data: PostData }
    url = formUrl;
  } else if (section === 'tableData') {
    postDataValue = {};
    url = formGetUrl;
  } else if (section === "getChat") {
    postDataValue = {};
    url = chatUrl;
  } else {
    postDataValue = { data: PostData };
    url = chatUrl;
  }

  try {
    const response = await axios({
      method: method,
      url: url,
      ...postDataValue,
      headers: headers
    });

    return response;
  } catch (error) {
    return error.response;
  }
}

// LoginCheck Order
async function LoginCheckByurl(token) {
  const headers = {
    'Authorization': `${token}` //localStorage.getItem('Token') //`${ token }`
  };

  try {
    const response = await axios.get(`${process.env.LoginCheck}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// get specific oder details
async function getOrderDetailForRefund(PostData = {}) {
  const headers = {

    'Authorization': `${PostData.token}`
  };

  try {
    const response = await axios.get(`${process.env.getOrderDetailForRefund}?order_id=${PostData.order_id}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

async function subDomainCheckFunction(PostData = {}, token) {
  const headers = {
    'Accept': "application/json"
  };

  try {
    const response = await axios.post(process.env.subDomainCheck, PostData, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

//legend page content api
async function getLegendPageFunction(slug = "us") {
  const headers = {
    'Accept': 'application/json',
  };
  try {
    const response = await axios.get(`${process.env.legendPageUrl}?slug=${slug}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

//pycnogenol page api

async function getPycnogenolFunction(slug = "us") {
  const headers = {
    'Accept': 'application/json',
  };
  try {
    const response = await axios.get(`${process.env.pycnogenol}?slug=${slug}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

//maritime prime content api
async function getMaritimePrimeFunction(slug = "us") {
  const headers = {
    'Accept': 'application/json',
  };
  try {
    const response = await axios.get(`${process.env.getMaritimePrimeApiUrl}?slug=${slug}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// update profileUpdate

async function profileUpdate(PostData = {}, token) {
  const headers = {
    'Authorization': `${token}`
  };

  try {
    const response = await axios.post(process.env.profileUpdate, PostData, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}



// GetCommissionsApproved Order
async function getAllTranaction(token, range, PostData = {}) {
  console.log("range", range)
  const headers = {
    'Authorization': `${token}`
  };

  try {
    const response = await axios.get(`${process.env.getAllTranaction}?value=${range}&from_date=${PostData?.from_date ? PostData.from_date : ''}&till_date=${PostData?.til_date ? PostData.til_date : ''}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

// set default card

async function defaultCard(token, Payload = {}) {
  const headers = {
    'Authorization': `${token}`
  };

  try {
    const response = await axios.post(process.env.defaultCard, Payload, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}


// Api for get downline user an d used in Dashboard

async function getNewDownlineUsers(token) {
  const headers = {

    'Authorization': `${token}`
  };

  try {
    const response = await axios.get(`${process.env.getNewDownlineUsers}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}


// getDownlineUserAddress
async function getDownlineUserAddress(token, orderID) {
  const headers = {
    'Authorization': `${token}`
  };

  try {
    const response = await axios.get(`${process.env.getDownlineUserAddress}?user_id=${orderID}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}


// getKaireCashTranaction
async function getKaireCashTranaction(token, range, PostData = {}) {
  const headers = {
    'Authorization': `${token}`
  };

  try {
    const response = await axios.get(`${process.env.getKaireCashTranaction}?value=${range}&from_date=${PostData?.from_date ? PostData.from_date : ''}&till_date=${PostData?.til_date ? PostData.til_date : ''}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

//transfer amount api
async function payoutAddEwalletBalance(postData) {
  const headers = {
    Authorization: localStorage.getItem("Token"),
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(
      process.env.payoutAddEwalletBalance,
      postData,
      { headers }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}



// Api for get dashboard news an d used in Dashboard

async function getDashboardNews(token) {
  const headers = {

    'Authorization': `${token}`
  };

  try {
    const response = await axios.get(`${process.env.getDashboardNews}`, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}



// update profileUpdate

async function profileImageUpload(PostData, token) {
  const headers = {
    'Authorization': `${token}`,
    // 'Content-Disposition': 'attachment; filename="My Report.png"'
    "Content-Type": "multipart/related; boundary=xy1z"
    // "Content- Disposition": ` form - data; name = "aFile"; filename = ${ PostData.file12.name } `

  };
  const formData = new FormData();

  // Update the formData object
  formData.append(
    "file",
    PostData
  );
  try {
    const response = await axios.post(process.env.profileImageUpload, formData, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}



// Api for get getDocuments

async function getDocuments(token) {
  const headers = {

    'Authorization': `${token} `
  };

  try {
    const response = await axios.get(`${process.env.getDocuments} `, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}


// Api for get getDocuments

async function downloadDoc(url) {
  const headers = {

    'Authorization': ``
  };

  try {
    const response = await axios.get(`${url} `, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

//multi Add to cart api

async function multiProductAddToCart(token, formData) {
  const headers = {
    'Authorization': `${token} `,
    'Content-Type': "application/json"
  };

  try {
    const response = await axios.post(`${process.env.multiProductAddToCart} `, formData, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}
async function getSavedCards(token) {
  const headers = {
    'Authorization': `${token} `,
    'Content-Type': "application/json"
  };
  const timestamp = Date.now();

  try {
    const response = await axios.get(`${process.env.getSavedCards}?xyz=${timestamp} `, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

//Card api section *********************************************************/

async function saveNewCard(token, payload) {         // card saved api 
  const headers = {
    'Authorization': `${token} `,
    'Content-Type': "application/json"
  };

  try {
    const response = await axios.post(`${process.env.saveNewCard} `, payload, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

async function deleteCard(token, payload) {          // card delete api 
  const headers = {
    'Authorization': `${token} `,
    'Content-Type': "application/json"
  };

  try {
    const response = await axios.post(`${process.env.deleteCard} `, payload, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

async function autoshipCardUpdate(token, payload) {          // card delete api 
  const headers = {
    'Authorization': `${token} `,
    'Content-Type': "application/json"
  };

  return axios.post(`${process.env.autoshipCardUpdate} `, payload, { headers }).then(function (response) {
    return response;
  }).catch((error) => {

    return error.response;
  });
}

//coupon list api

async function CouponListFunction() {
  const headers = {
    'Authorization': localStorage.getItem('Token'),
    'Content-Type': "application/json"
  };

  return axios.get(`${process.env.couponListApi} `, { headers }).then(function (response) {
    return response;
  }).catch((error) => {
    return error.response;
  });
}

//send Coupon api
async function SendCouponFunction(payload) {
  const headers = {
    'Authorization': localStorage.getItem('Token'),
    'Content-Type': "application/json"
  };

  return axios.post(`${process.env.sendCouponApi} `, payload, { headers }).then(function (response) {
    return response;
  }).catch((error) => {
    return error.response;
  });
}

//shipping amount apis ***********************************************/

async function availableShippingModules(token, payload) {
  const headers = {
    'Authorization': `${token} `,
    'Content-Type': "application/json"
  };

  try {
    const response = await axios.post(`${process.env.availableShippingModules} `, payload, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

async function calculateShipping(token, payload) {
  const headers = {
    'Authorization': `${token} `,
    'Content-Type': "application/json"
  };

  try {
    const response = await axios.post(`${process.env.calculateShipping} `, payload, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

//get upcoming autoship data

async function getUpcommingAutoship(token) {
  const headers = {
    'Authorization': `${token} `,
    'Content-Type': "application/json"
  };

  try {
    const response = await axios.get(`${process.env.getUpcommingAutoship} `, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}


//get kiare user data

async function getKaireUsers(token) {
  const headers = {
    'Authorization': `${token} `,
    'Content-Type': "application/json"
  };

  try {
    const response = await axios.get(`${process.env.getKaireUsers} `, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

async function getKairePageDetails(token) {
  const headers = {
    'Authorization': `${token} `,
    'Content-Type': "application/json"
  };

  try {
    const response = await axios.get(`${process.env.getKairePageDetails} `, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

async function getActiveSmartships(token) {
  const headers = {
    'Authorization': `${token} `,
    'Content-Type': "application/json"
  };

  try {
    const response = await axios.get(`${process.env.getActiveSmartships} `, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

async function getOrderInvoice(token, orderID) {
  const headers = {
    'Authorization': `${token} `,
    'Content-Type': "application/json"
  };

  try {
    const response = await axios.get(`${process.env.orderInvoice}?orderPublicID=${orderID} `, { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}

async function unsubscribedEmail({ req, ...payload }) {
  let URL = "";
  let headers = {
    "Content-Type": "application/json",
  };
  if (req === "get") {
    URL = await axios.get(
      `${process.env.unsubscribedEmail}?email=${payload?.email}`,
      { headers }
    );
  } else {
    URL = await axios.post(process.env.unsubscribedEmail, payload, { headers });
  }
  try {
    const response = URL;
    return response;
  } catch (error) {
    return error.response;
  }
}
async function MagicLink({ email }) {
  let headers = {
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.get(
      `${process.env.magicLinkEmail}?email=${email}`,
      { headers });
    return response;
  } catch (error) {
    return error.response;
  }
}



const api = {
  signUp,
  getBanners,
  loginUser,
  addToCart,
  logoutApi,
  getAllCartProduct,
  updateProductQty,
  deleteProductFromAddToCart,
  manageAddress,
  saveAddress,
  deleteAddress,
  getProfilePageData,
  changeUserPassword,
  addToWishlist,
  getAllWishListProduct,
  deleteProductFromwishlist,
  getAllProduct,
  getProductByCategories,
  getProductByProductid,
  getProductByVariantid,
  createOrder,
  verifyCopan,
  getAllCategory,
  updateCart,
  invitationLink,
  getUserOrder,
  getAddressDetails,
  GetOrderDetail,
  reorderProducts,
  getAddressList,
  autoshipOrderHistory,
  defaultCard,
  autoshipOrderById,
  autoshipUpdate,
  autoshipDelete,
  autoshipSkip,
  updateAddress,
  EditSmartshipData,
  getUserNotifications,
  autoshipProductDelete,
  autoshipProductUpateproduct,
  addressUpdate,
  resetPassword,
  verifyOtp,
  refundOrder,
  cancelOrder,
  getBundleProduct,
  getDownlineUsers,
  redeemKaireCash,
  getRefundHistory,
  getCommissionReport,
  getCommissionsFilter,
  getRefundReport,
  getRefundReportFilter,
  getMyProfileDetails,
  getDashboardCommissions,
  GetCommissionsApproved,
  calculateTaxAmount,
  LoginCheck,
  getOrderDetailForRefund,
  profileUpdate,
  getAllTranaction,
  getNewDownlineUsers,
  getDownlineUserAddress,
  getKaireCashTranaction,
  getDashboardNews,
  filterProduct,
  LoginCheckByurl,
  profileImageUpload,
  getDocuments,
  downloadDoc,
  getHomepageContent,
  multiProductAddToCart,
  upComingOrders,
  saveCustomerReview,
  getSavedCards,
  saveNewCard,
  deleteCard,
  availableShippingModules,
  calculateShipping,
  autoshipCardUpdate,
  getUpcommingAutoship,
  getKaireUsers,
  getKairePageDetails,
  getActiveSmartships,
  MenuSectionOption,
  subDomainCheckFunction,
  passwordResetConfirm,
  getProvenBanifitContent,
  getOrderPQVandGV,
  getLegendPageFunction,
  getPycnogenolFunction,
  getMaritimePrimeFunction,
  CouponListFunction,
  SendCouponFunction,
  promoterPackApi,
  clickedapi,
  supportDeskPost,
  CheckUserEmail,
  getdashboardpopup,
  payoutRegister,
  payoutAddEwalletBalance,
  permalinkAPI,
  getOrderInvoice,
  unsubscribedEmail,
  MagicLink
}
export default api;